import * as actionTypes from '../actions/actionType';
import { updateObject } from '../utility';


// Agent Status
const initialState = {
  agent_status_error: null,
  agent_status_loading: false,
  agent_status: 0,
  agent_registered: false,
  update_agent_status_error: false,
}

const loadAgentStatus = (state, action) => {
  return updateObject(state, {
    agent_status_error: null,
    agent_status_loading: true
  });
}

const loadDoneAgentStatus = (state, action) => {
  return updateObject(state, {
    agent_status: action.agent_status,
    agent_registered: action.agent_registered,
    agent_status_error: null,
    agent_status_loading: false
  });
}

const loadFailedAgentStatus = (state, action) => {
  return updateObject(state, {
    agent_status_error: action.error,
    agent_status_loading: false
  });
}

// Update Agent Status
const updateAgentStatusStarted = (state, action) => {
  return updateObject(state, {
    update_agent_status_error: false
  });
}

const updateAgentStatusDone = (state, action) => {
  return updateObject(state, {
    update_agent_status_error: false,
    agent_status: action.agent_status,
  });
}

const updateAgentStatusFailed = (state, action) => {
  return updateObject(state, {
    update_agent_status_error: true,
    agent_status: action.agent_status,
  });
}


// -------------------------
// REDUCER
// -------------------------
const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    // Load AgentStatus
    case actionTypes.LOAD_AGENT_STATUS_STARTED: return loadAgentStatus(state, action);
    case actionTypes.LOAD_AGENT_STATUS_DONE: return loadDoneAgentStatus(state, action);
    case actionTypes.LOAD_AGENT_STATUS_FAILED: return loadFailedAgentStatus(state, action);
    // Update AgentStatus
    case actionTypes.UPDATE_AGENT_STATUS_STARTED: return updateAgentStatusStarted(state, action);
    case actionTypes.UPDATE_AGENT_STATUS_DONE: return updateAgentStatusDone(state, action);
    case actionTypes.UPDATE_AGENT_STATUS_FAILED: return updateAgentStatusFailed(state, action);
    default:
      return state;
  }
};

export default agentReducer;
