import React from 'react';
// import { SipProvider } from 'react-sip';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import authReducer from './store/reducers/auth';
import articleReducer from './store/reducers/article';
import contactReducer from './store/reducers/contact';
import agentReducer from './store/reducers/agent';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
// import DynamicSipProvider from './components/DynamicSipProvider';
// import CallHandler from './components/CallHandler';


const rootReducer = combineReducers({
  auth: authReducer,
  article: articleReducer,
  contact: contactReducer,
  agent: agentReducer
})

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhances(
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
