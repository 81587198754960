import React from 'react';
import { connect } from 'react-redux';
import { Button, message, Tooltip } from 'antd';
import ReactAudioPlayer from 'react-audio-player';
import PhoneAction from '../components/PhoneAction';
// import PopoverDialExtension from '../components/PopoverDialExtension';
import FormDialExtension from '../components/FormDialExtension';
import { PhoneOutlined } from '@ant-design/icons';
// import { AudioMutedOutlined, PauseCircleOutlined, AudioOutlined, PlayCircleOutlined } from '@ant-design/icons';
// import { yellow, gold } from '@ant-design/colors';

import {
  // CALL_STATUS_IDLE,
  CALL_DIRECTION_INCOMING,
  CALL_DIRECTION_OUTGOING,
  CALL_STATUS_STARTING,
  CALL_STATUS_ACTIVE,
  callPropType,
  sipPropType
} from "react-sip";
import PropTypes from 'prop-types';

// const { Search } = Input;
// const { Option } = Select;


function getsipConfig() {
  // Set WEBRTC settings
  let WEBRTC_HOST = window.location.hostname;
  let WEBRTC_USER = localStorage.getItem('phone_login');
  let WEBRTC_PASSWORD = localStorage.getItem('phone_password');
  let WEBRTC_AUTOANSWER = localStorage.getItem('enable_webrtc_autoanswer') === 'true';
  let debug_mode = false;

  if (window._env_.DEBUG_MODE) {
    debug_mode = true;
  }

  if (window._env_.DEV_TEST_MODE) {
    WEBRTC_HOST = "agent.dialer.ai";
    WEBRTC_USER = window._env_.DEV_TEST_USER;
    WEBRTC_PASSWORD = window._env_.DEV_TEST_PASSWORD;
  }

  console.log("WebRTC Config: ", WEBRTC_HOST, WEBRTC_USER, WEBRTC_PASSWORD, WEBRTC_AUTOANSWER);

  const mysipConfig = {
    host: WEBRTC_HOST,
    port: 7443,
    pathname: "/", // Path in socket URI (e.g. wss://vc.dialer.ai:7443/ws); "" by default
    user: WEBRTC_USER,
    password: WEBRTC_PASSWORD, // usually required (e.g. from ENV or props)
    autoRegister: true, // true by default, see jssip.UA option register
    autoAnswer: WEBRTC_AUTOANSWER, // automatically answer incoming calls; false by default
    iceRestart: false, // force ICE session to restart on every WebRTC call; false by default
    sessionTimersExpires: 120, // value for Session-Expires header; 120 by default
    // extraHeaders: {{ // optional sip headers to send
    //   register: ['X-Foo: foo', 'X-Bar: bar'],
    //   invite: ['X-Foo: foo2', 'X-Bar: bar2']
    // }}

    // https://gist.github.com/mondain/b0ec1cf5f60ae726202e
    // Options
    // urls: [
    //   "stun:eu-turn5.xirsys.com",  // with all TURN bellow it, Joe experience ~40 sec delay
    //   "turn:eu-turn5.xirsys.com:80?transport=udp", // ES: Slow 5secs - UK: slow 40sec
    //   "turn:eu-turn5.xirsys.com:80?transport=tcp", // ES: Slow 7-8secs - UK: slow 40sec
    //   "turn:eu-turn5.xirsys.com:3478?transport=udp", // ES: Slow 4-5secs - UK: slow 40sec
    //   "turn:eu-turn5.xirsys.com:3478?transport=tcp", // ES: Slow 4-5secs - UK: slow 40sec
    //   "turns:eu-turn5.xirsys.com:443?transport=tcp", // ES: Slow 4-5secs - UK: slow 40sec
    //   "turns:eu-turn5.xirsys.com:5349?transport=tcp"  // ES: Slow 7secs - UK: slow 40sec
    // ]
    iceServers:
      [
        {
          urls: [
            // 'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302'
            // 'stun:stun.pjsip.org:3478' // FAST me & Joe
            // 'stun:stun.ekiga.net'
            // 'stun:stun.3cx.com:3478' // FAST me & Joe
            // 'stun:stun.zoiper.com:3478'
            // 'stun:eu-turn5.xirsys.com'
            // 'stun:turn-ams1.dialer.ai:60080' // ES: Slow 4-5secs - UK: slow 40sec
            // 'stun:stun.ekiga.net:3478'
            // 'stun:stun.services.mozilla.com',
            // 'stun:s1.voipstation.jp'
          ]
        },
        // {
        //   urls: 'turn:eu-turn5.xirsys.com:5349?transport=tcp',
        //   username: 'HxP6i3wnGN6aO5jk104bXJdr9VV0Y3ILJk4R33MfTSb1HpetlxrujooPnMqkTYAGAAAAAF41hyJzdGFyMmJpbGxpbmc=',
        //   credential: 'c7b7ef18-44fc-11ea-9b31-8e4d62b186e1'
        //   // urls: 'turn:turn-ams1.dialer.ai:60080', username: 'username1', credential: 'password1'
        // }

      ],
    // iceServers: {[ // optional
    //   { urls: ['stun:a.example.com', 'stun:b.example.com'] },
    //   { urls: 'turn:example.com', username: 'foo', credential: '1234' }
    // ]}
    debug: debug_mode // whether to output events to console; false by default
  };

  console.log('\n\nIceServers:');
  console.log(mysipConfig['iceServers'][0]['urls'][0]);
  // console.log(mysipConfig['iceServers'][1]['urls']);

  return mysipConfig;
}


class CallHandler extends React.Component {

  state = {
    showAnswer: false,
    webrtc_enabled: false,
  };

  componentDidMount() {
    // check if we should enalbe WebRTC
    const webrtc_enabled = localStorage.getItem('enable_webrtc') === 'true' ? true : false;
    this.setState({
      webrtc_enabled: webrtc_enabled,
    });

    this.timerID = setInterval(this.tickCheckStatus, 1000);
    const mysipConfig = getsipConfig();
    console.log("************************************** mysipConfig **************************");
    console.log(mysipConfig);
    this.context.updateSipConfig(mysipConfig);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    // this.context.unregisterSip(); // Not needed as the component will do that automatically
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.context.call);
    if (this.context.call.direction === CALL_DIRECTION_OUTGOING && this.context.call.status === CALL_STATUS_STARTING) {
      this.playRingback();
      if (prevState.showAnswer === true) {
        this.setState({
          showAnswer: false,
        });
      }
    } else if (this.context.call.direction === CALL_DIRECTION_INCOMING && this.context.call.status === CALL_STATUS_STARTING) {
      if (prevState.showAnswer === false) {
        this.playRing();
        message.info('Incoming call...', 1);
        this.setState({
          showAnswer: true,
        });
      }
    } else {
      if (prevState.showAnswer === true) {
        this.stopAudio();
        this.setState({
          showAnswer: false,
        });
      }
    }
  }

  tickCheckStatus = () => {
    // console.log(this.context.call.status);
    // console.log(this.context.sip.status);
    // console.log(this.context);
    // console.log(this.state);
  };

  playRing = () => {
    console.log("playRing:", this.rap.audioEl.current);
    this.rap.audioEl.current.src = "/telephone-ring-03a-silence.wav";
    this.rap.audioEl.current.loop = true;
  }
  playRingback = () => {
    console.log("playRingback:", this.rap.audioEl.current);
    this.rap.audioEl.current.src = "/US_ringback_tone.wav";
    this.rap.audioEl.current.loop = false;
  }
  stopAudio = () => {
    // console.log("stop audio:", this.rap.audioEl);
    this.rap.audioEl.current.src = "";
  }

  handleClickAnswerCall = e => {
    if (this.context.call.direction === CALL_DIRECTION_INCOMING && this.context.call.status === CALL_STATUS_STARTING) {
      this.context.answerCall();
    } else {
      console.log("Cannot answer call not started:", this.context.call.status, this.context.call.direction);
    }
  };

  handleClickManualDial = phone_number => {
    // console.log("phone_number:", phone_number);
    if (this.context.call.status !== CALL_STATUS_ACTIVE && this.context.call.status !== CALL_STATUS_STARTING) {
      this.context.startCall(phone_number);
    } else {
      console.log("Cannot answer call not started:", this.context.call.status, this.context.call.direction);
    }
  };

  handleClickHangUpCall = e => {
    if (this.context.call.status === CALL_STATUS_ACTIVE || this.context.call.status === CALL_STATUS_STARTING) {
      this.context.stopCall();
    } else {
      console.log("Cannot hangup call not active:", this.context.call.status);
    }
  };

  handleClickMuteCall = e => {
    console.log("Mute Call:", this.context.call.microphoneIsMuted);
    this.context.call.muteMicrophone();
  };
  handleClickUnmuteCall = e => {
    console.log("Unmute Call:", this.context.call.microphoneIsMuted);
    this.context.call.unmuteMicrophone();
  };

  handleClickHoldCall = e => {
    if (this.context.call.status === CALL_STATUS_ACTIVE || this.context.call.status === CALL_STATUS_STARTING) {
      console.log("Hold Call:", this.context.call.status);
      this.context.call.hold();
    } else {
      console.log("Cannot hold call not active:", this.context.call.status);
    }
  };

  handleClickUnholdCall = e => {
    if (this.context.call.status === CALL_STATUS_ACTIVE || this.context.call.status === CALL_STATUS_STARTING) {
      console.log("Unhold Call:", this.context.call.status);
      this.context.call.unhold();
    } else {
      console.log("Cannot unhold call not active:", this.context.call.status);
    }
  };

  handleClickSendDTMF = e => {
    console.log("SEND DTMF:", this.context.call.status);
  };

  handleClickPlayRing = e => {
    this.playRing();
  };

  handleClickPlayRingback = e => {
    this.playRingback();
  };

  handleClickStopAudio = e => {
    this.stopAudio();
  };

  render() {
    const { sip, call } = this.context;
    console.log("microphoneIsMuted:", call.microphoneIsMuted, " isOnHold:", call.isOnHold);
    let list_key_transfer = [];
    let subscriber_id = false;
    if (this.props.contact_data) {
      list_key_transfer = this.props.contact_data.callcenter_config.key_transfer;
      subscriber_id = this.props.contact_data.subscriber_id;
    }
    return (
      <div className="callhandler">
        {/* <Button icon="stop" size='default'
          style={{ "backgroundColor": red[5], "color": "#FFFFFF" }}
          onClick={this.handleClickHangUpCall}>
          Hangup
        </Button> */}

        {this.state.webrtc_enabled &&
          <div className="floating-box">
            <ReactAudioPlayer
              ref={(element) => { this.rap = element; }}
              src=""
              autoPlay={true}
              loop={true}
              volume={0.2}
            />
            <div className="sip-debug-message floating-box">
              <span>{call.direction} &nbsp;</span>
              <span>{call.status} &nbsp;</span>
              <span>{sip.status} &nbsp;</span>
            </div>

            {/* <div className="floating-box">
              <Popover content={PopoverDialExtension()}>
                <Search
                  placeholder="Number"
                  style={{ "width": 150, "verticalAlign": "middle" }}
                  onSearch={phone_number => this.handleClickManualDial(phone_number)}
                  enterButton="Dial"
                />
              </Popover>
            </div> */}

            <FormDialExtension callback_fn_dialextension={this.handleClickManualDial} />

            <AnswerButton
              activated={this.state.showAnswer}
              callback_answer_func={this.handleClickAnswerCall} />
          </div>
        }

        {this.props.children}

        <PhoneAction
          list_key_transfer={list_key_transfer}
          subscriber_id={subscriber_id}
          callback_fn_hangupcall={this.handleClickHangUpCall}
        />

        {/* {call.microphoneIsMuted ?
          <Tooltip placement="topLeft" title="Unmute">
            <Button
              icon={<AudioOutlined />}
              size='default'
              style={{ "backgroundColor": yellow[3], "color": "#FFFFFF" }}
              onClick={this.handleClickUnmuteCall}>
            </Button>
          </Tooltip>
          :
          <Tooltip placement="topLeft" title="Mute">
            <Button
              icon={<AudioMutedOutlined />}
              size='default'
              style={{ "backgroundColor": yellow[3], "color": "#FFFFFF" }}
              onClick={this.handleClickMuteCall}>
            </Button>
          </Tooltip>
        }

        {call.isOnHold ?
          <Tooltip placement="topLeft" title="Unhold">
            <Button
              icon={<PlayCircleOutlined />}
              size='default'
              style={{ "backgroundColor": gold[3], "color": "#FFFFFF" }}
              onClick={this.handleClickUnholdCall}>
            </Button>
          </Tooltip>
          :
          <Tooltip placement="topLeft" title="Hold">
            <Button
              icon={<PauseCircleOutlined />}
              size='default'
              style={{ "backgroundColor": gold[3], "color": "#FFFFFF" }}
              onClick={this.handleClickHoldCall}>
            </Button>
          </Tooltip>
        } */}

        {/* <Tooltip placement="topLeft" title="Send DTMF">
          <Button
            icon={<AppstoreOutlined />}
            size='default'
            style={{ "backgroundColor": gold[2], "color": "#FFFFFF" }}
            onClick={this.handleClickSendDTMF}>
          </Button>
        </Tooltip> */}

      </div>
    );
  }
}


class AnswerButton extends React.Component {

  render() {
    let className = '';
    if (this.props.activated) {
      className = 'answer-button-active';
    }
    return (
      <Tooltip placement="topLeft" title="Answer">
        <Button
          icon={<PhoneOutlined />}
          size='default'
          className={className}
          onClick={this.props.callback_answer_func}
          disabled={!this.props.activated}
        >
        </Button>
      </Tooltip>
    );
  }
}


CallHandler.contextTypes = {
  sip: sipPropType,
  call: callPropType,
  registerSip: PropTypes.func,
  unregisterSip: PropTypes.func,
  // call.microphoneIsMuted

  answerCall: PropTypes.func,
  startCall: PropTypes.func,
  stopCall: PropTypes.func,
  updateSipConfig: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    contact_data: state.contact.contact_data
  }
}


const mapDispatchToProps = dispatch => {
  return {
  }
}

// pure false was needed to fix a context issue between react-sip and redux
// when using connect() the context was not sync properly
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(CallHandler);

