import React from 'react';
import { Form, Input, Button, Divider } from 'antd';
import { connect } from 'react-redux';

// https://github.com/KillerCodeMonkey/ngx-quill/issues/351
// https://www.rockyourcode.com/assertion-failed-input-argument-is-not-an-html-input-element

class NoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note_sent: false,
      note_initial_value: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        note_sent: false,
        note_initial_value: '',
      });
    }
  }

  handleFormSubmit = (value, postFuncAddNote) => {
    console.log("value: ", value)
    console.log("value.note: ", value.note)
    postFuncAddNote(value.note);
    this.setState({
      note_sent: true,
    });
  }

  handleChange = (event) => {
    this.setState({
      note_initial_value: event.target.value
    });
  };

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  render() {
    return (
      <div>
        {
          this.state.note_sent ?
            <div>
              <Divider />
              <br />
            </div>
            :
            <div>
              <b>Add a note:</b>
              <Form
                onFinish={(value) => this.handleFormSubmit(value, this.props.postFuncAddNote)}
                onFinishFailed={this.onFinishFailed}
                initialValues={{ note: this.state.note_initial_value }}
                layout="inline"
              >
                <Form.Item name="note" >
                  <Input.TextArea
                    placeholder="Enter a note..."
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">{this.props.btnText}</Button>
                </Form.Item>
              </Form>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    note_error: state.contact.note_error
  }
}

export default connect(mapStateToProps)(NoteForm);
