import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Layout, Menu, Row, Col } from 'antd';
import { HomeOutlined, UnorderedListOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import AgentStatus from '../components/AgentStatus';
import * as actions from '../store/actions/auth';
import * as contact_actions from '../store/actions/contact';
import DynamicSipProvider from '../components/DynamicSipProvider';
import CallHandler from '../components/CallHandler';


// try this
const { Header, Sider, Content, Footer } = Layout;

function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


class CustomLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onClickLogout = () => {
    this.props.logout();
    // this.logout()
  }

  render() {
    // const logo_url = 'http://127.0.0.1:8000/static/autodialer/images/nd-branding/logo-icon.png';
    const logo_url = window._env_.REACT_APP_LOGO_NEG_URL;
    const version = Capitalize(process.env.REACT_APP_NAME) + ` v${process.env.REACT_APP_VERSION}`;
    const MySipProvider = DynamicSipProvider(CallHandler);
    // console.log("MySipProvider");
    // console.log(MySipProvider.props);
    // MySipProvider.updateSipConfig(mysipConfig);
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div />
          {
            <div>
              <img src={logo_url} alt="Logo" className="logo" />
              <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                <Menu.Item key="1">
                  <Link to="/">
                    <HomeOutlined />
                    <span>Home</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/lastcalls/">
                    <UnorderedListOutlined />
                    <span>Last calls</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="4" onClick={this.onClickLogout}>
                  <Link to="/logout/">
                    <LogoutOutlined />
                    <span>Logout</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </div>
          }

        </Sider>
        <Layout>
          <Header className="myheader">
            <Row type="flex" justify="space-between" align="top">
              {/* total 24 */}
              <Col span={2}>
                {
                  this.state.collapsed ?
                    <MenuUnfoldOutlined className="trigger" onClick={this.toggle} />
                    :
                    <MenuFoldOutlined className="trigger" onClick={this.toggle} />
                }
              </Col>
              <Col span={22}>
                <AgentStatus username={this.props.username} />
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="top">
              <Col span={24}>
                <MySipProvider />
                {/* <DynamicSipProvider>
                  <CallHandler />
                </DynamicSipProvider> */}
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}> {version} </Footer>
        </Layout>
      </Layout >
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    username: state.auth.username,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(actions.logout());
      dispatch(contact_actions.resetContact());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomLayout);
