import React from 'react';
import { Descriptions } from 'antd';
import moment from 'moment';


class ContactDetails extends React.Component {
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    // Clean up the contact_fields
    let contact_fields = this.props.contact_fields;
    console.log("subscriber_id: ", contact_fields['subscriber_id']);

    delete contact_fields['campaign_id'];
    delete contact_fields['contact_id'];
    delete contact_fields['subscriber_id'];
    delete contact_fields['internal_notes'];
    delete contact_fields['disposition'];
    delete contact_fields['tz_code'];
    delete contact_fields['gmt_offset'];
    delete contact_fields['count_attempt'];
    delete contact_fields['created_date'];
    let full_name = contact_fields['full_name'];
    let phone_number = contact_fields['phone_number'];
    let last_attempt = new Date(contact_fields['last_attempt']);
    last_attempt = moment(last_attempt).format('lll');
    delete contact_fields['full_name'];
    delete contact_fields['phone_number'];
    delete contact_fields['last_attempt'];

    console.log(contact_fields);
    const keys = Object.keys(contact_fields);
    let counter = 0;
    return (
      <div>
        <Descriptions title="Contact Info" bordered layout="vertical" column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label="Full name">
            {full_name}
          </Descriptions.Item>
          <Descriptions.Item label="Contact Number">
            {phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Last Called">
            {last_attempt}
          </Descriptions.Item>
          {
            keys.map(item => {
              let label = this.Capitalize(item);
              counter = counter + 1;
              return (
                <Descriptions.Item label={label} key={counter}>
                  {contact_fields[item]}
                </Descriptions.Item>
              );
            })
          }

        </Descriptions>
      </div>
    );
  }
};

export default ContactDetails;
