import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import ContactDetails from './ContactDetails';
import NoteForm from '../components/NoteForm';
import DNCButton from '../components/DNCButton';
import { Radio } from 'antd';
import * as actions from '../store/actions/contact';


function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

class UpdateDispositionButton extends React.Component {
  state = {
    initial_disposition: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        initial_disposition: null,
      });
    }
  }

  componentDidMount() {
    // Should not have a default, as we want to agent to click
    let current_disposition = '';
    if (this.props.current_disposition) {
      current_disposition = this.props.current_disposition.toString();
    }
    this.setState({
      initial_disposition: current_disposition,
    });
  }

  onChange = e => {
    this.setState({
      initial_disposition: e.target.value,
    });
    if (isFunction(this.props.postFuncUptDisposition)) {
      this.props.postFuncUptDisposition(e.target.value);
    }
  };

  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    let counter = 0;
    const keys = Object.keys(this.props.list_disposition);
    return (
      <div>
        <b>Update Disposition:</b><br />
        <Radio.Group onChange={this.onChange} value={this.state.initial_disposition}>
          {
            keys.map(item => {
              counter = counter + 1;
              return (
                <Radio style={radioStyle} value={item} key={counter}>
                  {this.props.list_disposition[item]}
                </Radio>
              );
            })
          }
        </Radio.Group>
      </div>
    );
  }
}


class ContactInfo extends React.Component {

  addToDNC = (subscriber_id, addtodnc) => {
    this.props.updateDNC(subscriber_id, addtodnc);

  }
  updateDisposition = (subscriber_id, disposition) => {
    this.props.updateDisposition(subscriber_id, disposition);
  }
  addNote = (subscriber_id, note) => {
    this.props.addNote(subscriber_id, note);
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={16}>
            {/* <PhoneAction
              list_key_transfer={this.props.contact_data.callcenter_config.key_transfer}
              subscriber_id={this.props.contact_data.subscriber_id}
            /> */}
            <ContactDetails contact_fields={this.props.contact_data.contact_fields} />
          </Col>
          <Col span={8}>
            <DNCButton
              subscriber_id={this.props.contact_data.subscriber_id}
              postFuncAddtoDNC={(addtodnc) => this.addToDNC(this.props.contact_data.subscriber_id, addtodnc)}
            />
            <UpdateDispositionButton
              list_disposition={this.props.contact_data.callcenter_config.lead_disposition}
              subscriber_id={this.props.contact_data.subscriber_id}
              current_disposition={this.props.contact_data.current_disposition}
              postFuncUptDisposition={(disposition) => this.updateDisposition(this.props.contact_data.subscriber_id, disposition)}
            />
            <NoteForm
              requestType="put"
              btnText="Add Note"
              subscriber_id={this.props.contact_data.subscriber_id}
              postFuncAddNote={(note) => this.addNote(this.props.contact_data.subscriber_id, note)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // token: state.auth.token,
    contact_data: state.contact.contact_data
  }
}


const mapDispatchToProps = dispatch => {
  return {
    updateDNC: (subscriber_id, add_dnc) => dispatch(actions.updateDNC(subscriber_id, add_dnc)),
    updateDisposition: (subscriber_id, disposition) => dispatch(actions.updateDisposition(subscriber_id, disposition)),
    addNote: (subscriber_id, note) => dispatch(actions.addNote(subscriber_id, note)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);

