import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Tooltip, Form, Col, Row, Select } from 'antd';
import * as actions from '../store/actions/agent';
import { red, blue, green } from '@ant-design/colors';
import { message } from 'antd';
import { StopOutlined, ArrowRightOutlined, DoubleRightOutlined } from '@ant-design/icons';

const { Option } = Select;


class PhoneAction extends React.Component {

  state = {
    size: 'default',
    value: null,
    dnc_value: false,
    visible: false,
    xfer_type: null,
    xfer_loading: false
  };

  componentDidMount() {
    // To disabled submit button at the beginning.
    // this.props.form.validateFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        value: null,
        dnc_value: false,
      });
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleClickHangupButton = e => {
    console.log("hangup subscriber_id: ", this.props.subscriber_id);
    actions.hangupCurrentCall(this.props.subscriber_id);
    message.error('Hangup signal sent!', 1);

    console.log('this.props.callback_fn_hangupcall:');
    if (this.props.callback_fn_hangupcall) {
      this.props.callback_fn_hangupcall();
    }
  };


  HandleTickResetAllXfer = () => {
    this.setState({
      xfer_loading: false
    });
    // message.success('Launched HandleTickResetAllXfer!', 1);
    clearInterval(this.timerID);
  };

  handleSubmitXfer = values => {
    // console.log('Accepted values on Form: ', values);
    const transfer_to = '##' + values['transfer_to'];
    console.log("xfer subscriber_id: " + this.props.subscriber_id + " - transfer_to:" + transfer_to);
    if (this.state.xfer_type === 'cold') {
      actions.XferCall(this.props.subscriber_id, 'cold_xfer', transfer_to);
      message.success('Cold Xfer requested!', 1);
    } else if (this.state.xfer_type === 'warm') {
      actions.XferCall(this.props.subscriber_id, 'warm_xfer', transfer_to);
      message.success('Warm Xfer requested!', 1);
    } else if (this.state.xfer_type === '3way') {
      actions.XferCall(this.props.subscriber_id, '3way_xfer', transfer_to);
      message.success('3Way Xfer requested!', 1);
    }
    this.timerID = setInterval(this.HandleTickResetAllXfer, 15000);
    this.setState({
      visible: false,
      xfer_loading: true,
    });
  };

  OpenDrawerColdXfer = e => {
    this.setState({
      xfer_type: 'cold',
    });
    this.showDrawer();
  };

  OpenDrawerWarmXfer = e => {
    this.setState({
      xfer_type: 'warm',
    });
    this.showDrawer();
  };

  OpenDrawer3WayXfer = e => {
    this.setState({
      xfer_type: '3way',
    });
    this.showDrawer();
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const { size } = this.state;
    let counter = 0;
    const keys = Object.keys(this.props.list_key_transfer);
    // let drawer_title = (this.state.xfer_type === 'cold' ? 'Cold Transfer' : 'Warm Transfer');
    let drawer_title = '';
    if (this.state.xfer_type === 'cold') {
      drawer_title = 'Cold Transfer';
    } else if (this.state.xfer_type === 'warm') {
      drawer_title = 'Warm Transfer';
    } else if (this.state.xfer_type === '3way') {
      drawer_title = '3Way Transfer';
    }
    return (
      <div className="floating-box">
        <Tooltip placement="topLeft" title="Hangup">
          <Button
            icon={<StopOutlined />}
            size={size}
            style={{ "backgroundColor": red[3], "color": "#FFFFFF" }}
            onClick={this.handleClickHangupButton}>
          </Button>
        </Tooltip>
        {
          this.props.list_key_transfer && this.props.subscriber_id
            ?
            <div className="floating-box">
              <Tooltip placement="topLeft" title="Cold Xfer">
                <Button
                  icon={<ArrowRightOutlined />}
                  size={size} loading={this.state.xfer_loading}
                  style={{ "backgroundColor": blue[3], "color": "#FFFFFF" }}
                  onClick={this.OpenDrawerColdXfer}>
                </Button>
              </Tooltip>

              <Tooltip placement="topLeft" title="3Way Xfer">
                <Button
                  icon={<DoubleRightOutlined />}
                  size={size} loading={this.state.xfer_loading}
                  style={{ "backgroundColor": green[3], "color": "#FFFFFF" }}
                  onClick={this.OpenDrawer3WayXfer}>
                </Button>
              </Tooltip>

              <Drawer
                title={drawer_title}
                width={400}
                onClose={this.onClose}
                visible={this.state.visible}
              >
                <Form
                  name="phoneaction_form"
                  layout="vertical"
                  hideRequiredMark
                  onFinish={this.handleSubmitXfer}
                >
                  <Row gutter={16}>
                    <Col span={12}>

                      <Form.Item label="Transfer to" name="transfer_to" rules={[{ required: true, message: 'Select to whom to transfer' }]}>
                        <Select name="transfer_to" placeholder="Select to whom to transfer">
                          {
                            keys.map(item => {
                              counter = counter + 1;
                              return (
                                <Option value={item} key={counter}>
                                  {this.props.list_key_transfer[item]}
                                </Option>
                              );
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Button type="primary" htmlType="submit">Transfer</Button>
                    </Col>
                  </Row>
                </Form>
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                  }}
                >
                  <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                </div>
              </Drawer>
            </div>
            :
            <span></span>

        }
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    // token: state.auth.token,
    contact_data: state.contact.contact_data
  }
}


const mapDispatchToProps = dispatch => {
  return {
  }
}


// const PhoneAction = Form.create({ name: 'phoneaction_form' })(PhoneActionForm);

export default connect(mapStateToProps, mapDispatchToProps)(PhoneAction);

