import React from 'react';
import { Tag } from 'antd';


class CallStatusTag extends React.Component {

  status_color = (callstatus) => {
    switch (callstatus) {
      case 'ANSWER':
        return 'green'
      case 'BUSY':
        return 'cyan'
      case 'NOANSWER':
        return 'geekblue'
      case 'FAILED':
        return 'magenta'
      default:
        return 'vocalno'
    }
  };

  render() {
    return (
      <Tag color={this.status_color(this.props.callstatus)}>{this.props.callstatus}</Tag>
    )
  }
}

export default CallStatusTag;