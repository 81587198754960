import React from 'react';
import { Switch } from 'antd';


function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

class DNCButton extends React.Component {
  state = {
    dnc_value: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.subscriber_id !== this.props.subscriber_id) {
      this.setState({
        dnc_value: false,
      });
    }
  }

  onChangeDNC = addtodnc => {
    this.setState({
      dnc_value: addtodnc,
    });
    if (isFunction(this.props.postFuncAddtoDNC)) {
      this.props.postFuncAddtoDNC(addtodnc);
    }
  };

  render() {
    return (
      <p>
        <Switch checkedChildren="Added To DNC" unCheckedChildren="&nbsp;&nbsp;&nbsp;&nbsp;Add To DNC" onChange={this.onChangeDNC} checked={this.state.dnc_value} />
      </p>
    );
  }
}

export default DNCButton;
