import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import Lastcalls from './containers/LastcallView';
import Leadboard from './containers/LeadboardView';
import ContactView from './containers/ContactView';
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';


const BaseRouter = () => (
  <div>
    <Route exact path='/' component={ContactView} />
    <Route path='/lastcalls' component={Lastcalls} />
    <Route path='/leadboard' component={Leadboard} />
    <Route
      exact
      path='/dialpad'
      render={() => <Result
        icon={<SmileOutlined />}
        title="Coming soon..."
      />}
    />
    <Route
      exact
      path='/logout'
      render={() => (
        <div>
          <h1>Logout</h1>
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        </div>
      )
      }
    />

  </div>
);

export default BaseRouter;