import React from 'react';
import { Form, Select } from 'antd';
// import PopoverDialExtension from '../components/PopoverDialExtension';

const { Option } = Select;


class FormDialExtension extends React.Component {

  state = {
    initial_extension: "0"
  };

  handleSelect = phone_number => {
    this.setState({
      initial_extension: phone_number
    });
    this.timerID = setInterval(this.ResetSelect, 3000);

    console.log("handleSelect: ", phone_number);
    if (this.props.callback_fn_dialextension && phone_number && phone_number !== "0") {
      this.props.callback_fn_dialextension(phone_number);
    }
  };

  ResetSelect = () => {
    console.log("ResetSelect");
    this.setState({
      initial_extension: "0"
    });
    clearInterval(this.timerID);
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return (
      // <Popover content={PopoverDialExtension()}>
      < Form name="basic" layout="inline" className="floating-box">
        <Form.Item name="select_extension">
          <Select
            placeholder="Dial an extension"
            onSelect={this.handleSelect}
            value={this.state.initial_extension}
          >
            <Option value="0">Dial an extension</Option>
            <Option value="1000">1000: Log out</Option>
            <Option value="1001">1001: Available</Option>
            <Option value="1002">1002: Standby</Option>
            <Option value="0001">0001: Echo Test</Option>
            <Option value="0002">0002: Tone Test</Option>
          </Select>

          {/* !!! The span bellow is a work around cause without that the value of the select cannot be reset using state */}
          <span style={{ left: "auto", marginLeft: "-0px", visibility: "hidden" }}>{this.state.initial_extension}</span>
        </Form.Item>
      </Form >
      // </Popover>
    )
  };
}


export default FormDialExtension;