// ----------------------------------
// This is a normal Component
// Usage as :
// in JSX
// <DynamicSipProvider>
//  <CallHandler />
// </DynamicSipProvider>

// import React from 'react';
// import PropTypes from 'prop-types';
// import { SipProvider } from 'react-sip';
// import { compose, withContext, withState } from 'recompose';


// Ref: https://github.com/callthemonline/simple-client-react/blob/a395367f5bd41370ca9c519b8fbe55d496fb6c3a/src/components/DynamicSipProvider.js

// const DynamicSipProvider = ({ sipConfig, children }) => (
//   <SipProvider {...sipConfig}
//     host="vc.dialer.ai"
//     port={7443}
//     pathname="/" // Path in socket URI (e.g. wss://vc.dialer.ai:7443/ws); "" by default
//     user="agent-99685"
//     password="223391258003" // usually required (e.g. from ENV or props)
//     autoRegister={true} // true by default, see jssip.UA option register
//     autoAnswer={false} // automatically answer incoming calls; false by default
//     iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
//     sessionTimersExpires={120} // value for Session-Expires header; 120 by default
//     // extraHeaders={{ // optional sip headers to send
//     //   register: ['X-Foo: foo', 'X-Bar: bar'],
//     //   invite: ['X-Foo: foo2', 'X-Bar: bar2']
//     // }}
//     iceServers={[
//       { urls: ['stun:stun.l.google.com:19302'] }
//     ]}
//     // iceServers={[ // optional
//     //   { urls: ['stun:a.example.com', 'stun:b.example.com'] },
//     //   { urls: 'turn:example.com', username: 'foo', credential: '1234' }
//     // ]}
//     debug={true} // whether to output events to console; false by default
//   >
//     {children}
//   </SipProvider>
// );

// export default compose(
//   withState('sipConfig', 'updateSipConfig'),
//   withContext(
//     {
//       updateSipConfig: PropTypes.func,
//     },
//     ({ updateSipConfig }) => ({ updateSipConfig }),
//   ),
// )(DynamicSipProvider);

// ----------------------------------
// This is a Higher Order Component (HOC)
// Usage as :
// before render
// const MySipProvider = DynamicSipProvider(CallHandler);
// then in JSX
// <MySipProvider />

import React from "react";
import PropTypes from 'prop-types';
import { SipProvider } from "react-sip";
import {
  callPropType,
  sipPropType
} from "react-sip";


const DynamicSipProvider = (WrappedComponent) => {
  return class DynamicSipProvider extends React.Component {
    static childContextTypes = {
      updateSipConfig: PropTypes.func,
      sip: sipPropType,
      call: callPropType,
    };
    state = {
      sipConfig: {},
      // sipConfig: mysipConfig,
    };

    updateSipConfig(newConfig) {
      this.setState({ sipConfig: newConfig });
    }

    getChildContext() {
      return {
        updateSipConfig: this.updateSipConfig.bind(this),
      };
    }

    // componentDidMount() {
    //   this.context.locale.subscribe(() => this.forceUpdate());
    // }

    render() {
      const { sipConfig } = this.state;
      return (
        <SipProvider {...sipConfig}>
          <WrappedComponent {...this.props} />
        </SipProvider>
      );
    }
  }
}

export default DynamicSipProvider;
