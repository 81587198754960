export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// Article
export const LOAD_ARTICLE = 'LOAD_ARTICLE';
export const LOAD_DONE_ARTICLE = 'LOAD_DONE_ARTICLE';
export const LOAD_FAILED_ARTICLE = 'LOAD_FAILED_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const UPDATE_DONE_ARTICLE = 'UPDATE_DONE_ARTICLE';
export const UPDATE_FAILED_ARTICLE = 'UPDATE_FAILED_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const DELETE_DONE_ARTICLE = 'DELETE_DONE_ARTICLE';
export const DELETE_FAILED_ARTICLE = 'DELETE_FAILED_ARTICLE';

// Contact
export const LOAD_CONTACT_STARTED = 'LOAD_CONTACT_STARTED';
export const LOAD_CONTACT_DONE = 'LOAD_CONTACT_DONE';
export const LOAD_CONTACT_FAILED = 'LOAD_CONTACT_FAILED';
export const UPDATE_DNC_STARTED = 'UPDATE_DNC_STARTED';
export const UPDATE_DNC_DONE = 'UPDATE_DNC_DONE';
export const UPDATE_DNC_FAILED = 'UPDATE_DNC_FAILED';
export const UPDATE_DISPOSITION_STARTED = 'UPDATE_DISPOSITION_STARTED';
export const UPDATE_DISPOSITION_DONE = 'UPDATE_DISPOSITION_DONE';
export const UPDATE_DISPOSITION_FAILED = 'UPDATE_DISPOSITION_FAILED';
export const RESET_CONTACT = 'RESET_CONTACT';

// Note
export const ADD_NOTE_STARTED = 'ADD_NOTE_STARTED';
export const ADD_NOTE_DONE = 'ADD_NOTE_DONE';
export const ADD_NOTE_FAILED = 'ADD_NOTE_FAILED';

// Agent Status
export const UPDATE_AGENT_STATUS_STARTED = 'UPDATE_AGENT_STATUS_STARTED';
export const UPDATE_AGENT_STATUS_DONE = 'UPDATE_AGENT_STATUS_DONE';
export const UPDATE_AGENT_STATUS_FAILED = 'UPDATE_AGENT_STATUS_FAILED';
export const LOAD_AGENT_STATUS_STARTED = 'LOAD_AGENT_STATUS_STARTED';
export const LOAD_AGENT_STATUS_DONE = 'LOAD_AGENT_STATUS_DONE';
export const LOAD_AGENT_STATUS_FAILED = 'LOAD_AGENT_STATUS_FAILED';
