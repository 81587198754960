import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Spin, Alert } from 'antd';
import { Row, Col, Avatar } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import * as actions from '../store/actions/auth';

import "../containers/Login.css";

class Login extends React.Component {
  handleSubmit = values => {
    console.log('Received values of form: ', values);
    // authLogin(values.username, values.password);
    this.props.onAuth(values.username, values.password);
  };

  render() {
    let errorMessage = null;
    if (this.props.error) {
      // errorMessage = (<p>{this.props.error}</p>)
      errorMessage = (<Alert message={this.props.error} type="error" banner closable />);
    }
    // const logo_url = 'http://127.0.0.1:8000/static/autodialer/images/nd-branding/logo-icon.png';
    const logo_url = window._env_.REACT_APP_LOGO_URL;
    return (
      <div>

        <Row type="flex" justify="center" className="login-form-row">
          <Col xs={20} sm={16} md={8} lg={8} xl={4} className="div-login-form" justify="center">
            <center>
              <Avatar
                // style={{ backgroundColor: '#DDDDDD' }}
                shape="square"
                size={64}
                src={logo_url}
              />
              <h1>Log in</h1>
              {errorMessage}
            </center>
            {
              this.props.loading ?
                <center><Spin size="large" /></center>
                :
                <Form onFinish={this.handleSubmit} className="login-form">
                  <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      autoComplete="current-password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Login
                    </Button>
                  </Form.Item>
                </Form>
            }
          </Col>
        </Row>
      </div>
    );
  }
}

// transform the redux state to props
const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
