import * as actionTypes from './actionType';
import { message } from 'antd';
import { prepAxiosHeaders } from '../utility';


export const loadContactStarted = () => {
  return {
    type: actionTypes.LOAD_CONTACT_STARTED
  }
}

export const resetContact = () => {
  console.log("-------------- resetContact");
  return {
    type: actionTypes.RESET_CONTACT
  }
}

export const loadContactDone = contact => {
  return {
    type: actionTypes.LOAD_CONTACT_DONE,
    contact_data: contact
  }
}

export const loadContactFailed = error => {
  return {
    type: actionTypes.LOAD_CONTACT_FAILED,
    contact_error: error
  }
}

// getArticle
export const fetchContact = () => {
  return dispatch => {
    dispatch(loadContactStarted());
    let h_axios = prepAxiosHeaders();
    if (h_axios) {
      h_axios.get(window._env_.REACT_APP_API_URL + '/api/agent/get_current_contact/')
        .then(res => {
          // console.log(res.data);
          if (res.data.status === 'OK') {
            dispatch(loadContactDone(res.data.data));
          } else {
            dispatch(loadContactFailed(res.data.status))
          }
        })
        .catch(err => {
          dispatch(loadContactFailed(err))
          console.log("Error loading...");
        })
    } else {
      dispatch(loadContactFailed('Axios headers error...'))
    }
  }
}


export const updateDispositionStarted = () => {
  return {
    type: actionTypes.UPDATE_DISPOSITION_STARTED
  }
}

export const updateDispositionDone = () => {
  message.success('Disposition Updated!', 1);
  return {
    type: actionTypes.UPDATE_DISPOSITION_DONE,
  }
}

export const updateDispositionFailed = error => {
  message.error('Error updating disposition!', 1);
  return {
    type: actionTypes.UPDATE_DISPOSITION_FAILED,
    disposition_error: error
  }
}

// update disposition
export const updateDisposition = (subscriber_id, disposition) => {
  return dispatch => {
    dispatch(updateDispositionStarted());
    let h_axios = prepAxiosHeaders();
    h_axios.post(window._env_.REACT_APP_API_URL + '/api/agent/update_disposition/',
      {
        subscriber_id: subscriber_id,
        disposition: disposition,
      })
      .then(res => {
        console.log(res.data);
        if (res.data.status === 'OK') {
          dispatch(updateDispositionDone());
        } else {
          dispatch(updateDispositionFailed(res.data.status))
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(updateDispositionFailed(err))
        console.log("Error loading...");
      });
  }
}

// DNC
export const updateDNCStarted = () => {
  return {
    type: actionTypes.UPDATE_DNC_STARTED
  }
}

export const updateDNCDone = () => {
  message.success('DNC Updated!', 1);
  return {
    type: actionTypes.UPDATE_DNC_DONE
  }
}

export const updateDNCFailed = error => {
  message.error('Error updating DNC!', 1);
  return {
    type: actionTypes.UPDATE_DNC_FAILED,
    dnc_error: error
  }
}

// update contact on DNC - either add or delete
export const updateDNC = (subscriber_id, add_dnc) => {
  return dispatch => {
    dispatch(updateDNCStarted());
    let h_axios = prepAxiosHeaders();
    h_axios.post(window._env_.REACT_APP_API_URL + '/api/agent/add_on_dnc/',
      {
        subscriber_id: subscriber_id,
        add_dnc: add_dnc,
      })
      .then(res => {
        console.log(res.data);
        if (res.data.status === 'OK') {
          dispatch(updateDNCDone());
        } else {
          dispatch(updateDNCFailed(res.data.status))
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(updateDNCFailed(err))
        console.log("Error loading...");
      });
  }
}


// DNC
export const addNoteStarted = () => {
  return {
    type: actionTypes.ADD_NOTE_STARTED
  }
}

export const addNoteDone = () => {
  message.success('Note has been sent!', 1);
  return {
    type: actionTypes.ADD_NOTE_DONE
  }
}

export const addNoteFailed = error => {
  message.error('Error sending the note!', 1);
  return {
    type: actionTypes.ADD_NOTE_FAILED,
    note_error: error
  }
}

// Add a new Note to a Contact
export const addNote = (subscriber_id, note) => {
  return dispatch => {
    dispatch(addNoteStarted());
    let h_axios = prepAxiosHeaders();
    h_axios.post(window._env_.REACT_APP_API_URL + '/api/agent/add_note/',
      {
        subscriber_id: subscriber_id,
        note: note,
      })
      .then(res => {
        console.log(res.data);
        if (res.data.status === 'OK') {
          dispatch(addNoteDone());
        } else {
          dispatch(addNoteFailed(res.data.status))
        }
      })
      .catch(err => {
        console.log("err:", err);
        dispatch(addNoteFailed(false))
        console.log("Error loading...");
      });
  }
}
