import React from 'react';
import { Table, Spin } from 'antd';
import CallStatusTag from '../components/CallStatusTag'
import moment from 'moment';
import { prepAxiosHeaders } from '../store/utility';

// let hrefLink = '#';

const columns = [
  {
    title: 'Campaign',
    dataIndex: 'campaign_name',
    key: 'campaign_name',
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    // render: text => <a href={hrefLink}>{text}</a>,
  },
  {
    title: 'Contact Name',
    dataIndex: 'full_name',
    key: 'full_name',
  },
  {
    title: 'Date',
    dataIndex: 'starting_date',
    key: 'starting_date',
    render: starting_date => {
      let local_date = new Date(starting_date)
      return (
        <span>
          {moment(local_date).format('lll')}
        </span>
      )
    },
  },
  {
    title: 'Duration',
    dataIndex: 'billsec',
    key: 'billsec',
  },
  {
    title: 'CallStatus',
    key: 'hangup_cause',
    dataIndex: 'hangup_cause',
    render: hangup_cause => (
      <span>
        <CallStatusTag callstatus={hangup_cause} />
      </span>
    ),
  },
  {
    title: 'Disposition',
    key: 'disposition',
    dataIndex: 'disposition',
  },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (text, record) => (
  //     <span>
  //       <a href={hrefLink}>Callback {record.phone_number}</a>
  //     </span>
  //   ),
  // },
];

//
// Sample data
//

// const bleg_data = [
//   {
//     "key": 2369,
//     "campaign_name": "VoiceCampaign 01",
//     "full_name": null,
//     "phone_number": "58987338023",
//     "starting_date": "2019-02-16T07:53:45.245358+01:00",
//     "billsec": 789,
//     "hangup_cause": "ANSWER",
//     "disposition": "TODO"
//   },
//   {
//     "key": 2370,
//     "campaign_name": "VoiceCampaign 02",
//     "full_name": null,
//     "phone_number": "16561578906",
//     "starting_date": "2019-02-18T03:05:45.254963+01:00",
//     "billsec": 598,
//     "hangup_cause": "BUSY",
//     "disposition": "TODO"
//   }
// ]

class Lastcalls extends React.Component {
  state = {
    call_data: [],
    loading: true,
    error: false
  }

  componentDidMount() {
    let h_axios = prepAxiosHeaders();
    h_axios.get(window._env_.REACT_APP_API_URL + '/api/agent/last_calls/')
      .then(res => {
        console.log(res.data.data);
        this.setState({
          call_data: res.data.data,
          loading: false
        });
      })
      .catch(err => {
        // console.log(err);
        this.setState({
          // call_data: bleg_data,
          loading: false
        });
        console.log("Error loading...");
      })
  }

  render() {
    return (
      this.state.loading ?
        <center><Spin size="large" /></center>
        :
        <Table columns={columns} dataSource={this.state.call_data} pagination={{ pageSize: 5 }} />
    );
  }

}

export default Lastcalls;
